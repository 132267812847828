/* External dependencies */
import gql from 'graphql-tag';

export const createLimitMutation = gql`
  mutation createLimit($input: CreateLimitInput!) {
    createLimit(input: $input) {
      name
    }
  }
`;
