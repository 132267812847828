// Local dependencies
import { Account } from '../../accounts/listAccounts/types';
import { User } from '../userTypes';

export enum GetProfileActionTypes {
  GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST',
  GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS',
  GET_PROFILE_ERROR = 'GET_PROFILE_ERROR',
  SELECTED_ACCOUNT = 'SELECTED_ACCOUNT',
}

export interface GetProfile {
  type: GetProfileActionTypes.GET_PROFILE_REQUEST;
  id: string;
}

export interface GetProfileSuccess {
  type: GetProfileActionTypes.GET_PROFILE_SUCCESS;
  user: User;
}

export interface SelectAccount {
  type: GetProfileActionTypes.SELECTED_ACCOUNT;
  account: Account;
}

export interface GetProfileError {
  type: GetProfileActionTypes.GET_PROFILE_ERROR;
  error: Error;
}

export type GetProfileAction = GetProfile | GetProfileSuccess | GetProfileError | SelectAccount;

export function getProfile(id?: string): GetProfile {
  return {
    type: GetProfileActionTypes.GET_PROFILE_REQUEST,
    id,
  };
}

export function getProfileSucceeded(user: User): GetProfileSuccess {
  return {
    type: GetProfileActionTypes.GET_PROFILE_SUCCESS,
    user,
  };
}

export function getProfileFailure(error: Error): GetProfileError {
  return {
    type: GetProfileActionTypes.GET_PROFILE_ERROR,
    error,
  };
}

export function selectAccount(account: Account): SelectAccount {
  return {
    type: GetProfileActionTypes.SELECTED_ACCOUNT,
    account,
  };
}
