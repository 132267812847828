// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { UserDocumentStatus } from '../../userTypes';
import { UpdateUserFields } from './actions';
import {
  UserBackImageRequiredException,
  UserBirthDayRequiredException,
  UserDocumentIdentificationStatusRequiredException,
  UserFaceIdentificationStatusRequiredException,
  UserFrontImageRequiredException,
  UserGenderRequiredException,
  UserIdentificationStatusRequiredException,
  UserIDRequiredException,
  UserIDRequiredFieldsException,
  UserLastNameRequiredException,
  UserNameRequiredException,
  UserPhoneRequiredFieldsException,
  UserSelfieImageRequiredException,
} from './exceptions';
import { GetUserState } from './reducer';

export default function validateUserFieldsForAdmin(state: GetUserState, updates: UpdateUserFields) {
  const {
    identificationStatus,
    documentNumber,
    documentIdentificationStatus,
    faceIdentificationStatus,
    front,
    back,
    selfie,
    pin,
    status,
    firstName,
    lastName,
    birthDate,
    patronymic,
    gender,
    groupName,
    phone,
    comment,
  } = updates;

  const change: any = {
    isFormChanged: {
      $set: true,
    },
  };

  function updateAndValidateField(nameOfUpdatedProperty: string, updatedField, errorProperty: string, exception) {
    if (!updatedField) {
      change[errorProperty] = {
        $set: exception,
      };
    } else {
      change['$unset'] = [errorProperty];
    }

    return update(state, {
      ...change,
      user: {
        $set: update(state.user, {
          [nameOfUpdatedProperty]: { $set: updatedField },
        }),
      },
    });
  }

  function updateField(nameOfUpdatedProperty: string, updatedField) {
    return update(state, {
      ...change,
      user: {
        $set: update(state.user, {
          [nameOfUpdatedProperty]: { $set: updatedField },
        }),
      },
    });
  }

  function updateAndValidatePersonalData(
    nameOfUpdatedProperty: string,
    updatedField: any,
    errorProperty: string,
    exception: any,
  ) {
    if (!updatedField) {
      change[errorProperty] = {
        $set: exception,
      };
    } else {
      change['$unset'] = [errorProperty];
    }

    if (state.user?.identificationDocument?.personalData !== null) {
      return update(state, {
        ...change,

        user: {
          $set: update(state.user, {
            identificationDocument: {
              $set:
                state.user?.identificationDocument &&
                update(state.user?.identificationDocument, {
                  personalData: {
                    $set: update(state.user?.identificationDocument?.personalData, {
                      [nameOfUpdatedProperty]: { $set: updatedField },
                    }),
                  },
                }),
            },
          }),
        },
      });
    }

    return update(state, {
      ...change,
      user: {
        $set: update(state.user, {
          identificationDocument: {
            $set:
              state.user?.identificationDocument &&
              update(state.user?.identificationDocument, {
                personalData: {
                  //@ts-ignore
                  $set: update(state.user?.identificationDocument?.personalData, {
                    [nameOfUpdatedProperty]: updatedField,
                  }),
                },
              }),
          },
        }),
      },
    });
  }

  function changeIdentificationStatus(state, comment: string, faceIdentificationStatus: string, hasChanged: boolean) {
    return update(state, {
      ...change,
      isIdentificationDocumentHasChanged: { $set: hasChanged },
      user: {
        $set: update(state.user, {
          identificationDocument: {
            $set:
              state.user?.identificationDocument &&
              update(state.user?.identificationDocument, {
                comment: { $set: comment },
                faceIdentificationStatus: { $set: faceIdentificationStatus as UserDocumentStatus },
              }),
          },
        }),
      },
    });
  }

  switch (true) {
    case updates.hasOwnProperty('firstName'): {
      return updateAndValidateField('firstName', firstName, 'firstNameError', new UserNameRequiredException());
    }

    case updates.hasOwnProperty('lastName'): {
      return updateAndValidateField('lastName', lastName, 'lastNameError', new UserLastNameRequiredException());
    }

    case updates.hasOwnProperty('patronymic'): {
      return updateField('patronymic', patronymic);
    }

    case updates.hasOwnProperty('birthDate'): {
      return updateAndValidateField('birthDate', birthDate, 'birthDateError', new UserBirthDayRequiredException());
    }

    case updates.hasOwnProperty('phone'): {
      return updateAndValidateField('phone', phone, 'phoneNumberError', new UserPhoneRequiredFieldsException());
    }

    case updates.hasOwnProperty('gender'): {
      return updateAndValidateField('gender', gender, 'genderError', new UserGenderRequiredException());
    }

    case updates.hasOwnProperty('status'): {
      return updateField('status', status);
    }

    case updates.hasOwnProperty('groupName'): {
      return updateField('groupName', groupName);
    }

    case updates.hasOwnProperty('documentNumber'): {
      return updateAndValidatePersonalData(
        'documentNumber',
        documentNumber,
        'documentNumberIDError',
        new UserIDRequiredFieldsException(),
      );
    }

    case updates.hasOwnProperty('back'): {
      return updateAndValidatePersonalData('back', back, 'backError', new UserBackImageRequiredException());
    }

    case updates.hasOwnProperty('front'): {
      return updateAndValidatePersonalData('front', front, 'frontError', new UserFrontImageRequiredException());
    }

    case updates.hasOwnProperty('selfie'): {
      return updateAndValidatePersonalData('selfie', selfie, 'selfieError', new UserSelfieImageRequiredException());
    }

    case updates.hasOwnProperty('pin'): {
      return updateAndValidatePersonalData('pin', pin, 'pinNumberError', new UserIDRequiredException());
    }

    case updates.hasOwnProperty('identificationStatus'): {
      return updateAndValidateField(
        'identificationStatus',
        identificationStatus,
        'identificationStatusError',
        new UserIdentificationStatusRequiredException(),
      );
    }

    case updates.hasOwnProperty('faceIdentificationStatus'): {
      if (!faceIdentificationStatus) {
        change.faceIdentificationStatusError = {
          $set: new UserFaceIdentificationStatusRequiredException(),
        };
      } else {
        change['$unset'] = ['faceIdentificationStatusError'];
      }

      return update(state, {
        ...change,
        user: {
          $set: update(state.user, {
            identificationDocument: {
              $set:
                state.user?.identificationDocument &&
                update(state.user?.identificationDocument, {
                  faceIdentificationStatus: { $set: faceIdentificationStatus },
                }),
            },
          }),
        },
      });
    }

    case updates.hasOwnProperty('documentIdentificationStatus'): {
      if (!documentIdentificationStatus) {
        change.documentIdentificationStatusError = {
          $set: new UserDocumentIdentificationStatusRequiredException(),
        };
      } else {
        change['$unset'] = ['documentIdentificationStatusError'];
      }

      return update(state, {
        ...change,
        user: {
          $set: update(state.user, {
            identificationDocument: {
              $set:
                state.user?.identificationDocument &&
                update(state.user?.identificationDocument, {
                  documentIdentificationStatus: { $set: documentIdentificationStatus },
                }),
            },
          }),
        },
      });
    }

    case updates.hasOwnProperty('identificationStatus'): {
      return updateIdentificationStatus('identificationStatus', identificationStatus);
    }

    case updates.hasOwnProperty('comment'): {
      if (state.user?.identificationDocument && !state.backupIdentificationDocument) {
        const forBackupFaceIdentificationStatus = state.user?.identificationDocument?.faceIdentificationStatus;
        const forBackupComment = state.user?.identificationDocument?.comment;

        return update(state, {
          ...change,
          isIdentificationDocumentHasChanged: { $set: true },
          backupIdentificationDocument: {
            $set: update(state.backupIdentificationDocument || {}, {
              faceIdentificationStatus: { $set: forBackupFaceIdentificationStatus },
              comment: { $set: forBackupComment },
            }),
          },
          user: {
            $set: update(state.user, {
              identificationDocument: {
                $set: update(state.user?.identificationDocument, {
                  comment: { $set: comment },
                  faceIdentificationStatus: { $set: UserDocumentStatus.FAILED },
                }),
              },
            }),
          },
        });
      }

      if (comment === 'restore' && state.isIdentificationDocumentHasChanged) {
        const backupComment = state?.backupIdentificationDocument?.comment || null;
        const backupFaceIdentificationStatus = state?.backupIdentificationDocument?.faceIdentificationStatus || null;
        return changeIdentificationStatus(state, backupComment, backupFaceIdentificationStatus, false);
      }

      return changeIdentificationStatus(state, comment, UserDocumentStatus.FAILED, true);
    }

    default: {
      return state;
    }
  }
}
