/*Local dependencies */
import { Limits } from '../../limitTypes';

export enum GetLimitActionTypes {
  GET_LIMIT_REQUEST = 'GET_LIMIT_REQUEST',
  GET_LIMIT_SUCCESS = 'GET_LIMIT_SUCCESS',
  GET_LIMIT_ERROR = 'GET_LIMIT_ERROR',
  GET_LIMIT_ERROR_RESET = 'GET_LIMIT_ERROR_RESET',
}

export interface GetLimit {
  type: GetLimitActionTypes.GET_LIMIT_REQUEST;
  id: string;
}

export interface GetLimitSuccess {
  type: GetLimitActionTypes.GET_LIMIT_SUCCESS;
  limit: Limits;
}

export interface GetLimitError {
  type: GetLimitActionTypes.GET_LIMIT_ERROR;
  error: Error;
}

export interface GetLimitErrorReset {
  type: GetLimitActionTypes.GET_LIMIT_ERROR_RESET;
}

export type GetLimitAction = GetLimit | GetLimitSuccess | GetLimitError | GetLimitErrorReset;

export function getLimit(id: string): GetLimit {
  return {
    type: GetLimitActionTypes.GET_LIMIT_REQUEST,
    id,
  };
}

export function getLimitSucceeded(limit: Limits): GetLimitSuccess {
  return {
    type: GetLimitActionTypes.GET_LIMIT_SUCCESS,
    limit,
  };
}

export function getLimitFailure(error: Error): GetLimitError {
  return {
    type: GetLimitActionTypes.GET_LIMIT_ERROR,
    error,
  };
}

export function getLimitsErrorReset(): GetLimitErrorReset {
  return {
    type: GetLimitActionTypes.GET_LIMIT_ERROR_RESET,
  };
}
