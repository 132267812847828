import { Service } from './../../services/types';

export enum Currency {
  KGS = 'KGS',
  RUB = 'RUB',
  USD = 'USD',
}

export type ServiceField = {
  name: string;
  value: string;
};

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  phone: string;
};

type PublicClient = {
  id: string,
  name: string,
  quickpayId: string,
}

export interface Transaction {
  amount: number;
  accountId: string;
  client: PublicClient,
  fields: ServiceField[];
  id: string;
  note: string;
  receiptNumber: string;
  recipient: User;
  requestDate: number;
  status: TransactionStatusType;
  statusMessage: string;
  service: Service;
  transactionDate: number;
  transactionId: string;
  transactionType: TransactionType;
  userId: string;
  sender?: {
    id: string,
    firstName: string,
    lastName: string,
    phone: string,
  },
  senderAccount?: {
    id: string,
    name: string,
  },
}

export enum TransactionStatusType {
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  SUCCEEDED = 'SUCCEEDED',
}

export enum TransactionType {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
  FEE = 'FEE',
  CLIENT_FEE_CREDIT = 'CLIENT_FEE_CREDIT',
  CLIENT_FEE_DEBIT = 'CLIENT_FEE_DEBIT',
}

export interface TransactionStatus {
  statusType: TransactionStatusType;
  statusReason: string;
}

export interface Money {
  amount: number;
  currency: Currency;
}

export enum FilterType {
  ALL_TYPES = 'ALL_TYPES',
  ALL_STATUSES = 'ALL_STATUSES',
  ALL = 'ALL',
  ALL_SERVICES = 'ALL_SERVICES',
}

export const transactionTypeFilter = [
  FilterType.ALL_TYPES,
  TransactionType.DEBIT,
  TransactionType.CREDIT,
  TransactionType.CLIENT_FEE_CREDIT,
  TransactionType.CLIENT_FEE_DEBIT,
];

export const transactionStatusFilter = [
  FilterType.ALL_STATUSES,
  TransactionStatusType.CANCELED,
  TransactionStatusType.FAILED,
  TransactionStatusType.PENDING,
  TransactionStatusType.PROCESSING,
  TransactionStatusType.SUCCEEDED,
];
