/*Local dependencies */
import { Limits } from '../../../../components/limits/limitTypes';

export enum UpdateLimitActionTypes {
  UPDATE_LIMIT_REQUEST = 'UPDATE_LIMIT_REQUEST',
}

export type UpdateLimitActions = UpdateLimitRequest;

export interface UpdateLimitRequest {
  type: UpdateLimitActionTypes.UPDATE_LIMIT_REQUEST;
  limit: Limits;
}

export function updateLimitRequest(limit: Limits): UpdateLimitRequest {
  return {
    type: UpdateLimitActionTypes.UPDATE_LIMIT_REQUEST,
    limit,
  };
}
