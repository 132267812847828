/*Local dependencies */
import { Limits } from '../limitTypes';

export enum ListLimitsActionTypes {
  LIST_LIMITS_REQUEST = 'LIST_LIMITS_REQUEST',
  LIST_LIMITS_SUCCESS = 'LIST_LIMITS_SUCCESS',
  LIST_LIMITS_ERROR = 'LIST_LIMITS_ERROR',
  RESET_LIMITS_ERROR = 'RESET_LIMITS_ERROR',
  SELECT_LIMIT = 'SELECT_LIMIT',
}

export interface ListLimits {
  type: ListLimitsActionTypes.LIST_LIMITS_REQUEST;
}

export interface ListLimitsSuccess {
  type: ListLimitsActionTypes.LIST_LIMITS_SUCCESS;
  limits: Limits[];
  total: number;
}

export interface ListLimitsError {
  type: ListLimitsActionTypes.LIST_LIMITS_ERROR;
  error: Error;
}

export interface ResetLimitsError {
  type: ListLimitsActionTypes.RESET_LIMITS_ERROR;
}

export type ListLimitsAction = ListLimits | ListLimitsSuccess | ListLimitsError | ResetLimitsError;

export function listLimits(): ListLimits {
  return {
    type: ListLimitsActionTypes.LIST_LIMITS_REQUEST,
  };
}

export function listLimitsSucceeded(limits: Limits[], total: number): ListLimitsSuccess {
  return {
    type: ListLimitsActionTypes.LIST_LIMITS_SUCCESS,
    limits,
    total,
  };
}

export function listLimitsFailed(error: Error): ListLimitsError {
  return {
    type: ListLimitsActionTypes.LIST_LIMITS_ERROR,
    error,
  };
}

export function resetLimitsError(): ResetLimitsError {
  return {
    type: ListLimitsActionTypes.RESET_LIMITS_ERROR,
  };
}
