/* External dependencies */
import gql from 'graphql-tag';

export const updateLimitMutation = gql`
  mutation updateLimit($input: UpdateLimitInput!) {
    updateLimit(input: $input) {
      name
    }
  }
`;
