function isObject(value) {
  return value !== null && typeof value === 'object';
}

export function validateLimit(limit, errors) {
  let validationPassed = true;

  Object.keys(limit).forEach((key) => {
    const value = limit[key];

    if (!isObject(value)) {
      if (value === null || value === '') {
        errors[key] = 'EnterValidValue';
        validationPassed = false;
      } else if (typeof value === 'string' && parseFloat(value) < -1) {
        errors[key] = 'EnterValidValue';
        validationPassed = false;
      } else {
        errors[key] = null;
      }
    } else {
      errors[key] = errors[key];
      const nestedValidationPassed = validateLimit(value, errors[key]);
      validationPassed = validationPassed && nestedValidationPassed;
    }
  });

  return validationPassed;
}
