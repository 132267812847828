/* Local dependencies */
import { SessionUser } from '../login/redux/actions';
import * as roles from './roles';
import UserRole = roles.UserRole;

export const QUANTITY_TERMINALS = ['1600+', '1 600+', '1,600', '1600'];
export const QUANTITY_USERS = ['700,000'];
export const QUANTITY_TURNOVER = ['2.6'];
export const IN_YEAR = '2010';
export const YEAR_IN_MARKET = calculateYearsInMarket(IN_YEAR);
export const QUANTITY_TRANSACTIONS = '1 000 000';
export const QUANTITY_TRANSACTIONS_TEXT = '1';
export const QUANTITY_PROVIDERS = '400';
export const NON_BREAKING_SPACE = '\u00A0';

export function getUserRole(currentUser: SessionUser) {
  if (!currentUser?.roles) {
    if (currentUser?.hasOwnProperty('cognito:preferred_role')) {
      currentUser.roles = roles.extractRoleFromARN(currentUser['cognito:preferred_role']);
    }
  }

  return currentUser?.roles;
}

export function isAccountant(currentUser: SessionUser): boolean {
  return getUserRole(currentUser) === UserRole.ACCOUNTANT;
}

export function isAdmin(currentUser: SessionUser): boolean {
  return getUserRole(currentUser) === UserRole.ADMIN;
}

export function isOperator(currentUser: SessionUser): boolean {
  return getUserRole(currentUser) === UserRole.OPERATOR;
}

export function isManager(currentUser: SessionUser): boolean {
  return getUserRole(currentUser) === UserRole.MANAGER;
}

export function isComplianceManager(currentUser: SessionUser): boolean {
  return getUserRole(currentUser) === UserRole.COMPLIANCE_MANAGER;
}

export function isDealer(currentUser: SessionUser): boolean {
  return getUserRole(currentUser) === UserRole.DEALER;
}

export function isUser(currentUser: SessionUser): boolean {
  return getUserRole(currentUser) === UserRole.USER;
}

/*
 * Cleans up the payload before sending to the server because
 * GraphQL does not accept undefined attributes.
 */
export function cleanPayload(payload: any) {
  Object.keys(payload).forEach((key: string) => {
    if (['__typename'].includes(key) || payload[key] === null || payload[key] === '' || ['typename'].includes(key)) {
      payload[key] = undefined;
    }

    if (typeof payload[key] === 'object') {
      payload[key] = cleanPayload(payload[key]);
    }
  });

  return payload;
}

export function removeSpaces(payload: any) {
  for (const [key, value] of Object.entries(payload)) {
    switch (true) {
      case ['__typename', 'typename'].includes(key):
      case value === null || value === '':
        payload[key] = undefined;
        break;
      case typeof value === 'string':
        payload[key] = (value as string).trim();
        break;
      case typeof value === 'object':
        payload[key] = removeSpaces(value);
        break;
      default:
        break;
    }
  }

  return payload;
}

export function addWhiteSpaceToPrefix(serviceInput: any) {
  if (serviceInput?.requiredFields) {
    serviceInput.requiredFields.forEach((field) => {
      if (field.hasSpaceAfterPrefix && field.prefix && !field.prefix.endsWith(NON_BREAKING_SPACE)) {
        field.prefix += NON_BREAKING_SPACE;
      }
    });
  }

  return serviceInput;
}

export function isMobile(): boolean {
  return typeof window !== 'undefined' ? window.innerWidth < 650 : false;
}

export function setLocalStorage(key: string, value: any) {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(key, value);
  }
}

export function setSessionStorage(key: string, value: any) {
  if (typeof sessionStorage !== 'undefined') {
    sessionStorage.setItem(key, value);
  }
}

export function getItemFromLocalStorage(key: string) {
  if (typeof localStorage !== 'undefined') {
    return localStorage.getItem(key);
  }

  return null;
}

export function removeLocalStorage(key: string) {
  localStorage.removeItem(key);
}

export function capitalizeFirstLetter(country: string) {
  return country.charAt(0).toUpperCase() + country.slice(1);
}

export interface Language {
  language?: string;
}

export function createSlug(string) {
  return string
    .toLowerCase()
    .replace(/[^a-z0-9]/g, '-')
    .replace(/-+/g, '-')
    .replace(/^-+|-$/g, '');
}

export function keyPressOnInputs(inputs: any) {
  inputs.forEach((input) => {
    input.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();

        const currentInputIndex = [...inputs].indexOf(e.target);
        const nextInput = inputs[currentInputIndex + 1];

        if (nextInput) {
          nextInput.focus();
        }
      }
    });
  });
}

export function splitString(str) {
  let characters = [];
  let separators = [];
  let currentCharacterCount = 0;

  for (let i = 0; i < str?.length; i++) {
    if (/[a-zA-Z0-9+]/.test(str[i])) {
      currentCharacterCount++;
    } else {
      if (currentCharacterCount > 0) {
        characters.push(currentCharacterCount);
        currentCharacterCount = 0;
      }

      separators.push(str[i]);
    }
  }

  if (currentCharacterCount > 0) {
    characters.push(currentCharacterCount);
  }

  return [characters, separators];
}

export function calculateYearsInMarket(startingYear: string) {
  const currentYear = new Date().getFullYear();

  return `${currentYear - parseInt(startingYear)}`;
}

/**
 * Checks if a value is empty.
 * @param {*} value - The value to be checked.
 * @returns {boolean} - True if the value is empty, otherwise false.
 */
function isEmptyValue(value: any): boolean {
  return (
    value == null || // isNullOrUndefined
    (Array.isArray(value) && value.length === 0) || // isEmptyArray
    (typeof value === 'string' && value.trim() === '') || // isEmptyString
    (typeof value === 'object' && Object.keys(value).length === 0) // isEmptyObject
  );
}

/**
 * Recursively cleans a payload object by removing empty fields and objects with all empty fields.
 * Additionally, it removes fields with names '__typename' or 'typename'.
 *
 * @param {object} payload - The payload object to be cleaned.
 * @returns {object} - The cleaned payload object.
 */
export function cleanPayloadV2(payload: { [key: string]: any }) {
  Object.keys(payload).forEach((key: string) => {
    const value = payload[key];

    const isTypename = key === '__typename' || key === 'typename';

    if (isEmptyValue(value) || isTypename) {
      delete payload[key];
    } else if (typeof value === 'object') {
      payload[key] = cleanPayloadV2(value);

      if (isEmptyValue(payload[key])) {
        delete payload[key];
      }
    }
  });

  return payload;
}

export function copyToClipboard(text) {
  navigator.clipboard.writeText(text);
}
