/* External dependencies */
import update from 'immutability-helper';

/*Local dependencies */
import { Limits } from '../limitTypes';
import { ListLimitsAction, ListLimitsActionTypes } from './actions';

export interface ListLimitsState {
  limits: Limits[];
  loading: boolean;
  total: number;
  error?: Error;
}

export const initialLimitsState: ListLimitsState = {
  limits: [],
  loading: false,
  total: 0,
};

export default function listLimitsReducer(state = initialLimitsState, action: ListLimitsAction) {
  switch (action.type) {
    case ListLimitsActionTypes.LIST_LIMITS_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case ListLimitsActionTypes.LIST_LIMITS_SUCCESS:
      return update(state, {
        loading: { $set: false },
        limits: { $set: action.limits },
        total: { $set: action.total },
      });

    case ListLimitsActionTypes.LIST_LIMITS_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case ListLimitsActionTypes.RESET_LIMITS_ERROR:
      return update(state, { $unset: ['error'] });

    default:
      return state;
  }
}
