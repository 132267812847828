/*External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/*Local dependencies */
import { getClient } from '../../../../clients/averspay';
import { Limits } from '../../limitTypes';
import { GetLimit, GetLimitAction, GetLimitActionTypes, getLimitFailure, getLimitSucceeded } from './actions';
import { getLimitQuery } from './queries';

export default function getLimitEpic(action$) {
  return action$.pipe(
    filter((action: GetLimitAction) => action.type === GetLimitActionTypes.GET_LIMIT_REQUEST),
    switchMap((action: GetLimit) =>
      getLimit(action)
        .then(getLimitSucceeded)
        .catch((error: Error) => getLimitFailure(error)),
    ),
  );
}

export async function getLimit({ id }: GetLimit): Promise<Limits> {
  const graphQLClient = await getClient();

  const {
    data: { getLimit },
  } = await graphQLClient.query({
    query: getLimitQuery,
    variables: {
      input: { id },
    },
  });

  return getLimit;
}
