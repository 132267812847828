// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { Limits } from '../../limitTypes';
import { GetLimitAction, GetLimitActionTypes } from './actions';

export interface GetLimitState {
  error?: Error;
  loading?: boolean;
  limit?: Limits;
}

export const initialGetLimitState: GetLimitState = {
  loading: false,
};

export default function getLimitReducer(state = initialGetLimitState, action: GetLimitAction) {
  switch (action.type) {
    case GetLimitActionTypes.GET_LIMIT_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case GetLimitActionTypes.GET_LIMIT_SUCCESS:
      return update(state, {
        limit: { $set: action.limit },
        loading: { $set: false },
      });

    case GetLimitActionTypes.GET_LIMIT_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    default:
      return state;
  }
}
