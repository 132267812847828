export interface Terminal {
  address: string;
  id: string;
  name: string;
  owner: {
    id: string;
    firstName?: string;
    lastName?: string;
  };
  registrationDate: number;
  status: DeviceStatus;
  technicalDetails: string;
  location: Location;
}

export type ServiceDevice = Pick<Terminal, 'id' | 'name'>;

export interface SparePart {
  deviceType: DeviceType;
  id: string;
  lastUpdatedDate: number;
  status: DeviceStatus;
}

export interface Location {
  lat: number;
  lon: number;
}

export enum DeviceStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  DELETED = 'DELETED',
  ACTIVE_NO_PAPER = 'ACTIVE_NO_PAPER',
  DISABLED = 'DISABLED',
  DISABLED_DUE_TO_BILL_COLLECTOR_ISSUE = 'DISABLED_DUE_TO_BILL_COLLECTOR_ISSUE',
  DISABLED_DUE_TO_HARDWARE_ISSUE = 'DISABLED_DUE_TO_HARDWARE_ISSUE',
  DISABLED_DUE_TO_LOW_BALANCE = 'DISABLED_DUE_TO_LOW_BALANCE',
  NEED_TO_BE_COLLECTED = 'NEED_TO_BE_COLLECTED',
  NO_CONNECTION = 'NO_CONNECTION',
}

export enum DeviceType {
  BillCollector = 'BillCollector',
  DeviceChange = 'DeviceChange',
  Monitor = 'Monitor',
  Motherboard = 'Motherboard',
  Printer = 'Printer',
  Storage = 'Storage',
  Terminal = 'Terminal',
}

export enum Operation {
  CASH_COLLECTED = 'CASH_COLLECTED',
  CONNECTION_LOST = 'CONNECTION_LOST',
  DEVICE_ATTACHED = 'DEVICE_ATTACHED',
  DEVICE_DEGRADATION_DETECTED = 'DEVICE_DEGRADATION_DETECTED',
  DEVICE_DETACHED = 'DEVICE_DETACHED',
  DEVICE_LOCATION_UPDATED = 'DEVICE_LOCATION_UPDATED',
  DEVICE_REPLACED = 'DEVICE_REPLACED',
  DEVICE_RETURNED_TO_STORAGE = 'DEVICE_RETURNED_TO_STORAGE',
  DEVICE_STATUS_CHANGED = 'DEVICE_STATUS_CHANGED',
  DEVICE_TAKEN_FROM_STORAGE = 'DEVICE_TAKEN_FROM_STORAGE',
  REGISTERED = 'REGISTERED',
  SIGNED_IN = 'SIGNED_IN',
}

export enum ActionsTerminalsMenuList {
  EDIT = 'edit',
}

export interface UpdateTerminalFields {
  name?: string;
  address?: string;
  status?: DeviceStatus;
}

export interface TerminalTechnician {
  devicesCount: number;
  firstName: string;
  id: string;
  lastName: string;
  phone: string;
  role: UserRole;
}

export enum UserRole {
  COLLECTOR = 'COLLECTOR',
  DEALER = 'DEALER',
  DEVICE = 'DEVICE',
  STOCK = 'STOCK',
  TECHNICIAN = 'TECHNICIAN',
  TECHNICIAN_AND_COLLECTOR = 'TECHNICIAN_AND_COLLECTOR',
}
