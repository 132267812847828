/* External dependencies */
import gql from 'graphql-tag';

export const updateServiceMutation = gql`
  mutation updateService($input: UpdateServiceInput!) {
    updateService(input: $input) {
      currency
      fees {
        dynamicFeeSteps {
          fee {
            flatFee {
              amount
              currency
            }
            flatFeePercent
          }
          from {
            amount
            currency
          }
        }
        flatFee {
          amount
          currency
        }
        flatFeePercent
      }
      id
      limit {
        id
      }
      logo {
        url
      }
      maxAmount
      minAmount
      name_en
      name_ru
      name_ky
      parentId
      requiredFields {
        fieldId
        inputMask
        label_en
        label_ky
        label_ru
        maxLength
        minLength
        prefix
        keyboardType
        value
      }
      slug
      status
      statusMessage
      serviceEndpoint {
        id
      }
      supportsDecimals
    }
  }
`;
