import { Limits, UpsertLimitInput } from '../../../../components/limits/limitTypes';

export enum CreateLimitActionTypes {
  CHANGE_LIMIT = 'CHANGE_LIMIT',
  CREATE_LIMIT_REQUEST = 'CREATE_LIMIT_REQUEST',
  CREATE_LIMIT_SUCCESS = 'CREATE_LIMIT_SUCCESS',
  CREATE_LIMIT_FAILURE = 'CREATE_LIMIT_FAILURE',
  RESET_CREATE_LIMIT = 'RESET_CREATE_LIMIT',
  RESET_CREATE_LIMIT_ERROR = 'RESET_CREATE_LIMIT_ERROR',
  SUBMIT_LIMIT = 'SUBMIT_LIMIT',
}

export type CreateLimitActions =
  | CreateLimitRequest
  | CreateLimitFailed
  | CreateLimitSuccess
  | ResetCreateLimit
  | ResetCreateLimitError
  | ChangeLimit
  | SubmitLimit;

export interface ResetCreateLimit {
  type: CreateLimitActionTypes.RESET_CREATE_LIMIT;
}

export interface ResetCreateLimitError {
  type: CreateLimitActionTypes.RESET_CREATE_LIMIT_ERROR;
}

export interface ChangeLimit {
  type: CreateLimitActionTypes.CHANGE_LIMIT;
  limit: UpsertLimitInput;
}

export interface CreateLimitRequest {
  type: CreateLimitActionTypes.CREATE_LIMIT_REQUEST;
  limit: Limits;
}

export interface CreateLimitSuccess {
  type: CreateLimitActionTypes.CREATE_LIMIT_SUCCESS;
}

export interface CreateLimitFailed {
  type: CreateLimitActionTypes.CREATE_LIMIT_FAILURE;
  error: Error;
}

export interface SubmitLimit {
  type: CreateLimitActionTypes.SUBMIT_LIMIT;
}

export function resetCreateLimit(): ResetCreateLimit {
  return {
    type: CreateLimitActionTypes.RESET_CREATE_LIMIT,
  };
}

export function resetCreateLimitError(): ResetCreateLimitError {
  return {
    type: CreateLimitActionTypes.RESET_CREATE_LIMIT_ERROR,
  };
}

export function createLimitFailed(error: Error): CreateLimitFailed {
  return {
    type: CreateLimitActionTypes.CREATE_LIMIT_FAILURE,
    error,
  };
}

export function createLimitRequest(limit: Limits): CreateLimitRequest {
  return {
    type: CreateLimitActionTypes.CREATE_LIMIT_REQUEST,
    limit,
  };
}

export function createLimitSucceeded(): CreateLimitSuccess {
  return {
    type: CreateLimitActionTypes.CREATE_LIMIT_SUCCESS,
  };
}

export function changeLimit(limit: UpsertLimitInput): ChangeLimit {
  return {
    type: CreateLimitActionTypes.CHANGE_LIMIT,
    limit,
  };
}

export function submitLimit(): SubmitLimit {
  return {
    type: CreateLimitActionTypes.SUBMIT_LIMIT,
  };
}
