/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/averspay';
import {
  getQueueMetricsFailed,
  getQueueMetricsSucceeded,
  QueueMetricsAction,
  QueueMetricsActionTypes,
  QueueMetricsSuccess,
  redriveInProgressQueueMetricsFailed,
  RedriveInProgressQueueMetricsRequest,
  redriveInProgressQueueMetricsSucceeded,
  RedriveInProgressQueueMetricsSuccess,
  redrivePendingQueueMetricsFailed,
  RedrivePendingQueueMetricsRequest,
  redrivePendingQueueMetricsSucceeded,
  RedrivePendingQueueMetricsSuccess,
} from './action';
import { redriveTransactionQueueMutation } from './mutation';
import { getTransactionsQueueMetricsQuery } from './query';
import { TransactionsQueueMetricsQueueType } from './types';

export default function transactionsQueueMetricsEpic(action$) {
  return action$.pipe(
    filter((action: QueueMetricsAction) => action.type === QueueMetricsActionTypes.QUEUE_METRICS_REQUEST),
    switchMap((action: QueueMetricsAction) =>
      transactionsQueueMetrics().then(getQueueMetricsSucceeded).catch(getQueueMetricsFailed),
    ),
  );
}

export async function transactionsQueueMetrics(): Promise<QueueMetricsSuccess> {
  const graphQLClient = await getClient();

  const {
    data: { getTransactionsQueueMetrics },
  } = await graphQLClient.query({
    query: getTransactionsQueueMetricsQuery,
  });

  return getTransactionsQueueMetrics;
}

export function redrivePendingQueueMetricsEpic(action$) {
  return action$.pipe(
    filter((action: QueueMetricsAction) => action.type === QueueMetricsActionTypes.REDRIVE_PENDING_QUEUE_METRICS_REQUEST),
    switchMap((action: RedrivePendingQueueMetricsRequest) =>
      redrivePendingQueueMetrics().then(redrivePendingQueueMetricsSucceeded).catch(redrivePendingQueueMetricsFailed),
    ),
  );
}

export async function redrivePendingQueueMetrics(): Promise<RedrivePendingQueueMetricsSuccess> {
  const graphQLClient = await getClient();
  const {
    data: { redriveTransactionQueue },
  } = await graphQLClient.mutate({
    mutation: redriveTransactionQueueMutation,
    variables: {
      input: {
        queueType: TransactionsQueueMetricsQueueType.PENDING_DLQ,
      },
    },
  });

  return redriveTransactionQueue;
}

export function redriveInProgressQueueMetricsEpic(action$) {
  return action$.pipe(
    filter((action: QueueMetricsAction) => action.type === QueueMetricsActionTypes.REDRIVE_IN_PROGRESS_QUEUE_METRICS_REQUEST),
    switchMap((action: RedriveInProgressQueueMetricsRequest) =>
      redriveInProgressQueueMetrics(action).then(redriveInProgressQueueMetricsSucceeded).catch(redriveInProgressQueueMetricsFailed),
    ),
  );
}

export async function redriveInProgressQueueMetrics({}: RedriveInProgressQueueMetricsRequest): Promise<RedriveInProgressQueueMetricsSuccess> {
  const graphQLClient = await getClient();

  const {
    data: { redriveTransactionQueue },
  } = await graphQLClient.mutate({
    mutation: redriveTransactionQueueMutation,
    variables: {
      input: {
        queueType: TransactionsQueueMetricsQueueType.IN_PROGRESS_DLQ,
      },
    },
  });

  return redriveTransactionQueue;
}
