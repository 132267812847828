/*External dependencies */
import { filter, switchMap } from 'rxjs';

/*Local dependencies */
import { getClient } from '../../../../clients/averspay';
import { createLimitFailed, createLimitSucceeded } from '../../../../components/limits/createLimit/redux/actions';
import { Limits } from '../../../../components/limits/limitTypes';
import { UpdateLimitActions, UpdateLimitActionTypes, UpdateLimitRequest } from './actions';
import { updateLimitMutation } from './mutation';

export default function updateLimitEpic(action$) {
  return action$.pipe(
    filter((action: UpdateLimitActions) => action.type === UpdateLimitActionTypes.UPDATE_LIMIT_REQUEST),
    switchMap((action: UpdateLimitRequest) =>
      updateLimit(action)
        .then(createLimitSucceeded)
        .catch((error: Error) => createLimitFailed(error)),
    ),
  );
}

export async function updateLimit(action): Promise<Limits> {
  const graphQLClient = await getClient();

  const { limit } = action;

  const {
    data: { updateLimit },
  } = await graphQLClient.mutate({
    mutation: updateLimitMutation,
    variables: {
      input: limit,
    },
  });

  return updateLimit;
}
