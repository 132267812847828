/* Local dependencies */
import { ServiceDevice } from '../../../../components/devices/terminals/types';
import {
  DynamicFeeType,
  RequiredServiceFieldInput,
  Service,
  UpdateServiceFields,
  UpdateServiceValidateFields,
} from '../../types';

export enum UpdateServiceActionTypes {
  UPDATE_SERVICE_REQUEST = 'UPDATE_SERVICE_REQUEST',
  UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS',
  UPDATE_SERVICE_ERROR = 'UPDATE_SERVICE_ERROR',
  RESET_UPDATE_SERVICE_STATE = 'RESET_UPDATE_SERVICE_STATE',
  UPDATE_ADD_DYNAMIC_FEE_VALUE = 'UPDATE_ADD_DYNAMIC_FEE_VALUE',
  UPDATE_ADD_ROW_INPUTS = 'UPDATE_ADD_ROW_INPUTS',
  UPDATE_SELECT_CURRENCY = 'UPDATE_SELECT_CURRENCY',
  UPDATE_REMOVE_ROW_INPUTS = 'UPDATE_REMOVE_ROW_INPUTS',
  RESET_UPDATE_DYNAMIC_FEE_VALUE = 'RESET_UPDATE_DYNAMIC_FEE_VALUE',
  RESET_UPDATE_ALL_DYNAMIC_INPUTS = 'RESET_UPDATE_ALL_DYNAMIC_INPUTS',
  UPDATE_RESET_FEE_VALUES = 'UPDATE_RESET_FEE_VALUES',
  UPDATE_SELECT_FEE_TYPE = 'UPDATE_SELECT_FEE_TYPE',
  UPDATE_SET_FEE_TYPE = 'UPDATE_SET_FEE_TYPE',
  UPDATE_SELECT_DYNAMIC_FEE_TYPE = 'UPDATE_SELECT_DYNAMIC_FEE_TYPE',
  UPDATE_SERVICE_FIELDS = 'UPDATE_SERVICE_FIELDS',
  UPDATE_RESET_ERROR = 'UPDATE_RESET_ERROR',
  UPDATE_GET_SERVICE_REQUEST = 'UPDATE_GET_SERVICE_REQUEST',
  UPDATE_GET_SERVICE_SUCCESS = 'UPDATE_GET_SERVICE_SUCCESS',
  UPDATE_GET_SERVICE_ERROR = 'UPDATE_GET_SERVICE_ERROR',
  UPDATE_SELECT_KEYBOARD_TYPE = 'UPDATE_SELECT_KEYBOARD_TYPE',
  UPDATE_RESET_KEYBOARD_TYPE = 'UPDATE_RESET_KEYBOARD_TYPE',
  UPDATE_REQUIREDFIELDS_INPUT = 'UPDATE_REQUIREDFIELDS_INPUT',
  UPDATE_SERVICE_REQUIREDFIELDS_ADD_INPUT = 'UPDATE_SERVICE_REQUIREDFIELDS_ADD_INPUT',
  UPDATE_SERVICE_REQUIREDFIELDS_ADD = 'UPDATE_SERVICE_REQUIREDFIELDS_ADD',
  UPDATE_SERVICE_REQUIREDFIELDS_REMOVE_INPUT = 'UPDATE_SERVICE_REQUIREDFIELDS_REMOVE_INPUT',
  UPDATE_RESET_FIXED_COMMISSION = 'UPDATE_RESET_FIXED_COMMISSION',
  SHOW_CONFIRMATION_POPUP = 'SHOW_CONFIRMATION_POPUP',
  HIDE_CONFIRMATION_POPUP = 'HIDE_CONFIRMATION_POPUP',
  UPDATE_SERVICE_PARENT_ID_LABEL = 'UPDATE_SERVICE_PARENT_ID_LABEL',
  SELECT_DEVICE_UPDATE = 'SELECT_DEVICE_UPDATE',
  REMOVE_DEVICE_UPDATE = 'REMOVE_DEVICE_UPDATE',
}

export interface SelectDevices {
  type: UpdateServiceActionTypes.SELECT_DEVICE_UPDATE;
  selectedDevice: ServiceDevice;
}

export interface SelectDevicesRemove {
  type: UpdateServiceActionTypes.REMOVE_DEVICE_UPDATE;
  selectedDevice: ServiceDevice;
}

export interface UpdateServiceRequest {
  type: UpdateServiceActionTypes.UPDATE_SERVICE_REQUEST;
  service: UpdateServiceFields;
}

export interface UpdateServiceSuccess {
  type: UpdateServiceActionTypes.UPDATE_SERVICE_SUCCESS;
  service: Service;
}

export interface UpdateServiceError {
  type: UpdateServiceActionTypes.UPDATE_SERVICE_ERROR;
  error: Error;
}

export interface UpdateRequiredFieldsValues {
  type: UpdateServiceActionTypes.UPDATE_REQUIREDFIELDS_INPUT;
  value: RequiredServiceFieldInput;
  index: number;
}

export interface UpdateServiceRequiredFieldsAdd {
  type: UpdateServiceActionTypes.UPDATE_SERVICE_REQUIREDFIELDS_ADD_INPUT;
}

export interface UpdateServiceAddRequiredFields {
  type: UpdateServiceActionTypes.UPDATE_SERVICE_REQUIREDFIELDS_ADD;
  requiredFields: any[];
}

export interface UpdateResetError {
  type: UpdateServiceActionTypes.UPDATE_RESET_ERROR;
}

export interface UpdateServiceRequiredFieldsRemove {
  type: UpdateServiceActionTypes.UPDATE_SERVICE_REQUIREDFIELDS_REMOVE_INPUT;
  index: number;
}

export interface ResetUpdateServiceState {
  type: UpdateServiceActionTypes.RESET_UPDATE_SERVICE_STATE;
}

export interface UpdateUpdateServiceFields {
  type: UpdateServiceActionTypes.UPDATE_SERVICE_FIELDS;
  updates: UpdateServiceValidateFields;
}

export interface SelectFeesType {
  type: UpdateServiceActionTypes.UPDATE_SELECT_FEE_TYPE;
  fee: string;
}

export interface SetFeesType {
  type: UpdateServiceActionTypes.UPDATE_SET_FEE_TYPE;
  fee: any;
}

export interface ResetFeesValues {
  type: UpdateServiceActionTypes.UPDATE_RESET_FEE_VALUES;
}

export interface AddFeeValues {
  type: UpdateServiceActionTypes.UPDATE_ADD_DYNAMIC_FEE_VALUE;
  values: any;
}

export interface SelectDynamicFeesType {
  type: UpdateServiceActionTypes.UPDATE_SELECT_DYNAMIC_FEE_TYPE;
  feeType: DynamicFeeType;
  index: number;
}

export interface AddRowInputs {
  type: UpdateServiceActionTypes.UPDATE_ADD_ROW_INPUTS;
}

export interface RemoveRowInputs {
  type: UpdateServiceActionTypes.UPDATE_REMOVE_ROW_INPUTS;
  index: number;
}

export interface ResetAllDynamicInputs {
  type: UpdateServiceActionTypes.RESET_UPDATE_ALL_DYNAMIC_INPUTS;
}

export interface ResetDynamicFeeValue {
  type: UpdateServiceActionTypes.RESET_UPDATE_DYNAMIC_FEE_VALUE;
  index: number;
  value: any;
}

export interface GetServiceRequest {
  type: UpdateServiceActionTypes.UPDATE_GET_SERVICE_REQUEST;
  id: string;
  acquiring?: boolean;
}

export interface GetServiceSuccess {
  type: UpdateServiceActionTypes.UPDATE_GET_SERVICE_SUCCESS;
  service: Service;
}

export interface GetServiceError {
  type: UpdateServiceActionTypes.UPDATE_GET_SERVICE_ERROR;
  error: Error;
}

export interface ShowConfirmationPopup {
  type: UpdateServiceActionTypes.SHOW_CONFIRMATION_POPUP;
}

export interface HideConfirmationPopup {
  type: UpdateServiceActionTypes.HIDE_CONFIRMATION_POPUP;
}

export interface ResetFixedCommission {
  type: UpdateServiceActionTypes.UPDATE_RESET_FIXED_COMMISSION;
  values: any;
}

export interface UpdateServiceParentIdLabel {
  type: UpdateServiceActionTypes.UPDATE_SERVICE_PARENT_ID_LABEL;
  label: string;
}

export type UpdateServiceActions =
  | UpdateServiceRequest
  | UpdateServiceSuccess
  | UpdateServiceError
  | ResetUpdateServiceState
  | UpdateUpdateServiceFields
  | SelectFeesType
  | ResetFeesValues
  | AddFeeValues
  | SelectDynamicFeesType
  | AddRowInputs
  | RemoveRowInputs
  | ResetAllDynamicInputs
  | ResetDynamicFeeValue
  | GetServiceRequest
  | GetServiceSuccess
  | GetServiceError
  | UpdateRequiredFieldsValues
  | UpdateServiceAddRequiredFields
  | UpdateServiceRequiredFieldsAdd
  | UpdateResetError
  | UpdateServiceRequiredFieldsRemove
  | ShowConfirmationPopup
  | HideConfirmationPopup
  | ResetFixedCommission
  | SetFeesType
  | SelectDevices
  | SelectDevicesRemove
  | UpdateServiceParentIdLabel;

export function selectDeviceUpdate(selectedDevice: ServiceDevice): SelectDevices {
  return {
    type: UpdateServiceActionTypes.SELECT_DEVICE_UPDATE,
    selectedDevice,
  };
}

export function selectDevicesRemoveUpdate(selectedDevice: ServiceDevice): SelectDevicesRemove {
  return {
    type: UpdateServiceActionTypes.REMOVE_DEVICE_UPDATE,
    selectedDevice,
  };
}

export function updateService(service: UpdateServiceFields): UpdateServiceRequest {
  const newRequriedFields = service?.requiredFields?.map((el, index) => {
    return {
      ...el,
      orderNumber: index,
      prefix: el.prefix?.trim(),
    };
  });

  const newService = { ...service, requiredFields: newRequriedFields };

  return {
    type: UpdateServiceActionTypes.UPDATE_SERVICE_REQUEST,
    service: newService,
  };
}

export function updateServiceSucceeded(service: Service): UpdateServiceSuccess {
  return {
    type: UpdateServiceActionTypes.UPDATE_SERVICE_SUCCESS,
    service,
  };
}

export function updateServiceFailed(error: Error): UpdateServiceError {
  return {
    type: UpdateServiceActionTypes.UPDATE_SERVICE_ERROR,
    error,
  };
}

export function updateResetError(): UpdateResetError {
  return {
    type: UpdateServiceActionTypes.UPDATE_RESET_ERROR,
  };
}

export function updateRequiredFieldsValues(value: any, index: number): UpdateRequiredFieldsValues {
  return {
    type: UpdateServiceActionTypes.UPDATE_REQUIREDFIELDS_INPUT,
    value,
    index,
  };
}

export function updateServiceRequiredFieldsAdd(): UpdateServiceRequiredFieldsAdd {
  return {
    type: UpdateServiceActionTypes.UPDATE_SERVICE_REQUIREDFIELDS_ADD_INPUT,
  };
}

export function updateServiceAddRequiredField(requiredFields: any[]): UpdateServiceAddRequiredFields {
  return {
    type: UpdateServiceActionTypes.UPDATE_SERVICE_REQUIREDFIELDS_ADD,
    requiredFields,
  };
}

export function updateServiceRequiredFieldsRemove(index: number): UpdateServiceRequiredFieldsRemove {
  return {
    type: UpdateServiceActionTypes.UPDATE_SERVICE_REQUIREDFIELDS_REMOVE_INPUT,
    index,
  };
}

export function resetUpdateServiceState(): ResetUpdateServiceState {
  return { type: UpdateServiceActionTypes.RESET_UPDATE_SERVICE_STATE };
}

export function updateUpdateServiceFields(updates: UpdateServiceValidateFields): UpdateUpdateServiceFields {
  return {
    type: UpdateServiceActionTypes.UPDATE_SERVICE_FIELDS,
    updates,
  };
}

export function selectFeesType(fee: string): SelectFeesType {
  return {
    type: UpdateServiceActionTypes.UPDATE_SELECT_FEE_TYPE,
    fee,
  };
}

export function setFeesType(fee: any): SetFeesType {
  return {
    type: UpdateServiceActionTypes.UPDATE_SET_FEE_TYPE,
    fee,
  };
}

export function resetFeesValues(): ResetFeesValues {
  return {
    type: UpdateServiceActionTypes.UPDATE_RESET_FEE_VALUES,
  };
}

export function setDynamicFeeValue(values: any): AddFeeValues {
  return {
    type: UpdateServiceActionTypes.UPDATE_ADD_DYNAMIC_FEE_VALUE,
    values,
  };
}

export function resetFixedCommissionUpdate(values: any): ResetFixedCommission {
  return {
    type: UpdateServiceActionTypes.UPDATE_RESET_FIXED_COMMISSION,
    values,
  };
}

export function selectDynamicFeesType(feeType: DynamicFeeType, index: number): SelectDynamicFeesType {
  return {
    type: UpdateServiceActionTypes.UPDATE_SELECT_DYNAMIC_FEE_TYPE,
    feeType,
    index,
  };
}

export function addRowInputs(): AddRowInputs {
  return {
    type: UpdateServiceActionTypes.UPDATE_ADD_ROW_INPUTS,
  };
}

export function removeRowInputs(index: number): RemoveRowInputs {
  return {
    type: UpdateServiceActionTypes.UPDATE_REMOVE_ROW_INPUTS,
    index,
  };
}

export function resetAllDynamicInputs(): ResetAllDynamicInputs {
  return {
    type: UpdateServiceActionTypes.RESET_UPDATE_ALL_DYNAMIC_INPUTS,
  };
}

export function resetDynamicFeeValue(index: number, value: any): ResetDynamicFeeValue {
  return {
    type: UpdateServiceActionTypes.RESET_UPDATE_DYNAMIC_FEE_VALUE,
    index,
    value,
  };
}

export function getService(id: string, acquiring = false): GetServiceRequest {
  return {
    type: UpdateServiceActionTypes.UPDATE_GET_SERVICE_REQUEST,
    id,
    acquiring,
  };
}

export function getServiceSucceeded(service: Service): GetServiceSuccess {
  return {
    type: UpdateServiceActionTypes.UPDATE_GET_SERVICE_SUCCESS,
    service,
  };
}

export function getServiceFailed(error: Error): GetServiceError {
  return {
    type: UpdateServiceActionTypes.UPDATE_GET_SERVICE_ERROR,
    error,
  };
}

export function showConfirmationPopupService(): ShowConfirmationPopup {
  return {
    type: UpdateServiceActionTypes.SHOW_CONFIRMATION_POPUP,
  };
}

export function hideConfirmationPopupService(): HideConfirmationPopup {
  return {
    type: UpdateServiceActionTypes.HIDE_CONFIRMATION_POPUP,
  };
}

export function updateServiceParentIdLabel(label: string): UpdateServiceParentIdLabel {
  return {
    type: UpdateServiceActionTypes.UPDATE_SERVICE_PARENT_ID_LABEL,
    label,
  };
}
