/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { createSlug } from '../../../common/helpers';
import { CreateCategoryState } from '../../createCategory/redux/reducer';
import { MIN_NUMBER_FOR_COMMISSION, ServiceStatus, UpdateCategoryFields, UpdateServiceFields } from '../../types';
import { UpdateCategoryState } from '../../updateCategory/redux/reducer';
import { UpdateServiceState } from '../../updateService/redux/reducer';
import {
  AmountServiceFeesRequiredException,
  CurrencyRequiredException,
  DynamicsFeeStepsRequiredException,
  FlatFeePercentRequiredException,
  LabelRequiredException,
  LimitRequiredException,
  LogoRequiredException,
  MaxAmountRequiredException,
  MinAmountRequiredException,
  NameRequiredException,
  NameRequiredExceptionInEnglish,
  ParentIdRequiredException,
  PopularityScoreRequiredException,
  ServerRequiredException,
  ServiceEndpointRequiredException,
  StatusMessageRequiredException,
  StatusRequiredException,
} from './exceptions';
import { CreateServiceState } from './reducer';

export function validateServiceFields(
  state: CreateServiceState | UpdateServiceState | UpdateCategoryState | CreateCategoryState,
  updates: UpdateServiceFields | UpdateCategoryFields,
) {
  const {
    amount,
    currency,
    flatFeePercent,
    maxAmount,
    minAmount,
    name_en,
    name_ru,
    name_ky,
    status,
    server,
    statusMessage,
    limit,
    logo,
    parentId,
    popularityScore,
    dynamicFeeStepsCheck,
    serviceEndpoint,
    supportsDecimals,
  } = updates;
  const change: any = {
    isFormChanged: {
      $set: true,
    },
  };

  if (updates.hasOwnProperty('limit')) {
    if (!limit) {
      change.limitError = {
        $set: new LimitRequiredException(),
      };
    } else {
      change['$unset'] = ['limitError'];
    }

    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          limit: { $set: { id: limit?.id } },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('status')) {
    if (!status || !ServiceStatus.hasOwnProperty(status)) {
      change.statusError = {
        $set: new StatusRequiredException(),
      };
    } else {
      change['$unset'] = ['statusError'];
    }

    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          status: { $set: status },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('maxAmount')) {
    if (!maxAmount) {
      change.maxAmountError = {
        $set: new MaxAmountRequiredException(),
      };
    } else {
      change['$unset'] = ['maxAmountError'];
    }

    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          maxAmount: { $set: maxAmount },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('statusMessage')) {
    if (!statusMessage) {
      change.statusMessageError = {
        $set: new StatusMessageRequiredException(),
      };
    } else {
      change['$unset'] = ['statusMessageError'];
    }

    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          statusMessage: { $set: statusMessage },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('minAmount')) {
    if (!minAmount) {
      change.minAmountError = {
        $set: new MinAmountRequiredException(),
      };
    } else {
      change['$unset'] = ['minAmountError'];
    }

    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          minAmount: { $set: minAmount },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('parentId')) {
    if (!parentId?.value) {
      change.parentIdError = {
        $set: new ParentIdRequiredException(),
      };
    } else {
      change['$unset'] = ['parentIdError'];
    }

    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          parentId: { $set: parentId?.value },
        }),
      },
      selectedParentIdLabel: {
        $set: parentId?.label,
      },
    });
  }

  if (updates.hasOwnProperty('popularityScore')) {
    if (!popularityScore) {
      change.popularityScoreError = {
        $set: new PopularityScoreRequiredException(),
      };
    } else {
      change['$unset'] = ['popularityScoreError'];
    }

    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          popularityScore: { $set: popularityScore },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('currency')) {
    if (!currency) {
      change.currencyError = {
        $set: new CurrencyRequiredException(),
      };
    } else {
      change['$unset'] = ['currencyError'];
    }

    return update(state, {
      ...change,
      currency: {
        $set: currency,
      },

      service: {
        $set: update(state?.service, {
          currency: { $set: currency },
          fees: {
            $set: update(state?.service?.fees, {
              flatFee: {
                $set:
                  state?.service?.fees?.flatFee &&
                  update(state?.service?.fees?.flatFee, {
                    currency: {
                      $set: currency,
                    },
                  }),
              },
              dynamicFeeSteps: {
                $set:
                  state.service?.fees?.dynamicFeeSteps &&
                  update(state.service?.fees?.dynamicFeeSteps, {
                    $apply: (dynamicFeeSteps) =>
                      dynamicFeeSteps.map((item) => {
                        return update(item, {
                          from: {
                            $set: update(item.from, {
                              $merge: { currency: currency },
                            }),
                          },
                          fee: {
                            $set: update(item.fee, {
                              flatFee: {
                                $merge: { currency: currency },
                              },
                            }),
                          },
                        });
                      }),
                  }),
              },
            }),
          },
        }),
      },
    });
  }

  // It is flatFee's amount
  if (updates.hasOwnProperty('amount')) {
    if (!amount || amount <= MIN_NUMBER_FOR_COMMISSION) {
      change.amountError = { $set: new AmountServiceFeesRequiredException() };
    } else {
      change['$unset'] = ['amountError'];
    }

    return update(state, {
      ...change,
      service: {
        $set: update(state?.service, {
          fees: {
            $set: update(state?.service?.fees, {
              flatFee: {
                $set: update(state?.service?.fees?.flatFee, {
                  amount: { $set: updates['amount'] },
                }),
              },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('flatFeePercent')) {
    if (!flatFeePercent || flatFeePercent <= MIN_NUMBER_FOR_COMMISSION) {
      change.flatFeePercentError = {
        $set: new FlatFeePercentRequiredException(),
      };
    } else {
      change['$unset'] = ['flatFeePercentError'];
    }

    return update(state, {
      ...change,
      service: {
        $set: update(state?.service, {
          fees: {
            $set: update(state?.service?.fees, {
              flatFeePercent: {
                $set: flatFeePercent,
              },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('name_ru')) {
    if (!name_ru) {
      change.nameRuError = {
        $set: new NameRequiredException(),
      };
    } else {
      change['$unset'] = ['nameRuError'];
    }

    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          name_ru: { $set: name_ru.replace(/\s+/g, ' ') },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('name_ky')) {
    if (!name_ky) {
      change.nameKyError = {
        $set: new NameRequiredException(),
      };
    } else {
      change['$unset'] = ['nameKyError'];
    }

    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          name_ky: { $set: name_ky.replace(/\s+/g, ' ') },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('name_en')) {
    let slug = createSlug(name_en);
    const newText = name_en.replace(/[^a-zA-Z0-9=\-_$&()\s]+/gi, '');

    if (!name_en) {
      change.nameEnError = {
        $set: new NameRequiredExceptionInEnglish(),
      };
    } else {
      change['$unset'] = ['nameEnError'];
    }

    if (!newText) {
      change.nameEnError = {
        $set: new NameRequiredExceptionInEnglish(),
      };
    } else {
      slug = createSlug(name_en);
    }

    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          name_en: { $set: newText },
          slug: { $set: slug },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('logo')) {
    if (!logo) {
      change.logoError = {
        $set: new LogoRequiredException(),
      };
    } else {
      change['$unset'] = ['logoError'];
    }

    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          logo: {
            $set: update(state.service.logo, {
              url: { $set: logo },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('server')) {
    if (!server) {
      change.serverError = {
        $set: new ServerRequiredException(),
      };
    } else {
      change['$unset'] = ['serverError'];
    }

    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          server: { $set: server },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('dynamicFeeStepsCheck')) {
    if (dynamicFeeStepsCheck === 'emptyDynamicFeeSteps') {
      change.dynamicFeeStepsError = {
        $set: new DynamicsFeeStepsRequiredException(),
      };
    } else {
      change['$unset'] = ['dynamicFeeStepsError'];
    }

    return update(state, { ...change });
  }

  if (updates.hasOwnProperty('serviceEndpoint')) {
    if (!serviceEndpoint) {
      change.serviceEndpointError = {
        $set: new ServiceEndpointRequiredException(),
      };
    } else {
      change['$unset'] = ['serviceEndpointError'];
    }

    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          serviceEndpoint: {
            $set: update(state.service?.serviceEndpoint, {
              id: { $set: serviceEndpoint?.value },
            }),
          },
        }),
      },
      selectedServiceEndpoint: {
        $set: serviceEndpoint?.label,
      },
    });
  }

  if (updates.hasOwnProperty('supportsDecimals')) {
    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          supportsDecimals: { $set: supportsDecimals },
        }),
      },
    });
  }

  return state;
}

export function validateComission(state: CreateServiceState | UpdateServiceState, action) {
  const { feeType, dynamicFeeStepsField } = action;

  const change: any = {};

  if (action.hasOwnProperty('dynamicFeeStepsField')) {
    let isErrorDynamicFeeSteps = null;

    if (dynamicFeeStepsField === 'emptyDynamicFeeSteps') {
      isErrorDynamicFeeSteps = new DynamicsFeeStepsRequiredException();
    }

    return update(state, {
      ...change,
      dynamicFeeStepsField: {
        $set: isErrorDynamicFeeSteps,
      },
    });
  }

  if (action.hasOwnProperty('feeType')) {
    let isErrorFeeType = null;

    if (!feeType) {
      isErrorFeeType = new LabelRequiredException();
    }

    return update(state, {
      ...change,
      feeType: {
        $set: action.feeType,
      },
      feeTypeError: {
        $set: isErrorFeeType,
      },
    });
  }

  return state;
}
