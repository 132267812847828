/* External dependencies */
import gql from 'graphql-tag';

export const getLimitQuery = gql`
  query getLimit($input: GetLimitInput!) {
    getLimit(input: $input) {
      id
      name
      identified {
        corporate {
          monthlyTransactionLimit
          transactionLimit
        }
        personal {
          monthlyTransactionLimit
          transactionLimit
        }
      }
      offlineIdentified {
        corporate {
          monthlyTransactionLimit
          transactionLimit
        }
        personal {
          monthlyTransactionLimit
          transactionLimit
        }
      }
    }
  }
`;
