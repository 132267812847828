/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/averspay';
import { ServiceDevice } from '../../../../components/devices/terminals/types';
import { getTerminal } from '../../../../components/devices/terminals/updateTerminal/redux/epic';
import { addWhiteSpaceToPrefix, cleanPayloadV2, removeSpaces } from '../../../common/helpers';
import { KeyboardType, Service } from '../../types';
import {
  GetServiceRequest,
  UpdateServiceActionTypes,
  UpdateServiceActions,
  UpdateServiceRequest,
  getServiceFailed,
  getServiceSucceeded,
  updateServiceFailed,
  updateServiceSucceeded,
} from './action';
import { updateServiceMutation } from './mutation';
import { getServiceQuery } from './queries';

export function updateServiceEpic(action$) {
  return action$.pipe(
    filter((action: UpdateServiceActions) => action.type === UpdateServiceActionTypes.UPDATE_SERVICE_REQUEST),
    switchMap((action: UpdateServiceRequest) =>
      updateService(action)
        .then(updateServiceSucceeded)
        .catch((error: Error) => updateServiceFailed(error)),
    ),
  );
}

export async function updateService(action): Promise<Service> {
  const graphQLClient = await getClient();
  const { service } = action;

  const input = Object.keys(service).reduce((input, key) => {
    if (service.hasOwnProperty(key) && typeof service[key] !== 'undefined') {
      input[key] = service[key];
    }

    return input;
  }, {});

  const cleanedService = cleanPayloadV2(input);
  const serviceWithoutSpaces = removeSpaces(cleanedService);
  const serviceWithWhiteSpaceToPrefix = addWhiteSpaceToPrefix(serviceWithoutSpaces);

  serviceWithWhiteSpaceToPrefix.fees = null;

  serviceWithWhiteSpaceToPrefix.requiredFields = serviceWithWhiteSpaceToPrefix.requiredFields?.map((item) => {
    const { hasSpaceAfterPrefix, ...newItem } = item;
    return newItem;
  });

  const {
    data: { updateService },
  } = await graphQLClient.mutate({
    mutation: updateServiceMutation,
    variables: { input: { devices: [], ...serviceWithWhiteSpaceToPrefix } },
  });

  return updateService as Service;
}

export function getServiceEpic(action$) {
  return action$.pipe(
    filter((action: UpdateServiceActions) => action.type === UpdateServiceActionTypes.UPDATE_GET_SERVICE_REQUEST),
    switchMap((action: GetServiceRequest) =>
      getService(action)
        .then(getServiceSucceeded)
        .catch((error: Error) => getServiceFailed(error)),
    ),
  );
}

export async function getService(action: GetServiceRequest): Promise<Service> {
  const graphQLClient = await getClient();
  const { id, acquiring } = action;

  const {
    data: { getService },
  } = await graphQLClient.query({
    query: getServiceQuery,
    variables: { input: { id } },
  });

  let service: Service;

  if (acquiring) {
    service = {
      ...getService,
      requiredFields: [
        ...getService.requiredFields,
        {
          fieldId: 'email',
          inputMask: null,
          keyboardType: KeyboardType.TEXT,
          label_en: 'Email',
          label_ky: 'Электрондук дарек',
          label_ru: 'Электронный адрес',
          minLength: 3,
          maxLength: null,
          prefix: null,
          orderNumber: service?.requiredFields!.length,
          value: null,
        },
        {
          fieldId: 'serviceId',
          inputMask: null,
          keyboardType: KeyboardType.TEXT,
          label_en: null,
          label_ky: null,
          label_ru: null,
          minLength: null,
          maxLength: null,
          prefix: null,
          value: getService.id,
        },
        {
          fieldId: 'transactionId',
          inputMask: null,
          keyboardType: KeyboardType.TEXT,
          label_en: null,
          label_ky: null,
          label_ru: null,
          minLength: null,
          maxLength: null,
          prefix: null,
          value: null,
        },
      ],
    };
  } else {
    service = getService;
  }

  if (service.devices) {
    const promises = service?.devices?.map(({ id }) => getTerminal({ id }));

    const devices = await Promise.all(promises);

    const devicesMap = new Map(devices.map((device) => [device.id, device]));

    service.devices.forEach((device: ServiceDevice) => {
      device.name = devicesMap.get(device.id).name;
    });
  }

  return service as Service;
}
