// Local dependencies
import { FeeEntity } from '../../../fees/serviceFees/types';
import { DeviceStatus, DeviceType, Terminal } from '../types';

export enum ListTerminalsActionTypes {
  LIST_TERMINALS_REQUEST = 'LIST_TERMINALS_REQUEST',
  LIST_TERMINALS_SUCCESS = 'LIST_TERMINALS_SUCCESS',
  LIST_TERMINALS_ERROR = 'LIST_TERMINALS_ERROR',
  SELECT_ENTITY_TYPE = 'SELECT_ENTITY_TYPE',
  SELECT_TERMINAL = 'SELECT_TERMINAL',
  SELECT_TERMINAL_SINGLE = 'SELECT_TERMINAL_SINGLE',
  RESET_SELECTED_TERMINALS = 'RESET_SELECTED_TERMINALS',
  RESET_LIST_TERMINALS = 'RESET_LIST_TERMINALS',
  RESET_LIST_TERMINALS_ERROR = 'RESET_LIST_TERMINALS_ERROR',
}

export interface ListTerminals {
  type: ListTerminalsActionTypes.LIST_TERMINALS_REQUEST;
  currentPage?: number;
  searchString: string;
  filter?: ListTerminalsFilter;
}

export const DEFAULT_ADMIN_TERMINALS_FILTER = { typename: DeviceType.Terminal };
export const DEFAULT_FEES_TERMINALS_FILTER = { typename: DeviceType.Terminal };

export interface ListTerminalsFilter {
  statuses?: DeviceStatus;
  typename?: DeviceType;
}

export interface ListTerminalsSuccess {
  type: ListTerminalsActionTypes.LIST_TERMINALS_SUCCESS;
  terminals: Terminal[];
  total: number;
}

export interface ListTerminalsError {
  type: ListTerminalsActionTypes.LIST_TERMINALS_ERROR;
  error: Error;
}

export interface SelectEntityType {
  type: ListTerminalsActionTypes.SELECT_ENTITY_TYPE;
  entityType: FeeEntity;
}

export interface SelectTerminal {
  type: ListTerminalsActionTypes.SELECT_TERMINAL;
  terminal: Terminal;
}

export interface SelectTerminalSingle {
  type: ListTerminalsActionTypes.SELECT_TERMINAL_SINGLE;
  terminal: Terminal;
}

export interface ResetSelectedTerminals {
  type: ListTerminalsActionTypes.RESET_SELECTED_TERMINALS;
}

export interface ResetListTerminals {
  type: ListTerminalsActionTypes.RESET_LIST_TERMINALS;
}

export interface ResetListTerminalsError {
  type: ListTerminalsActionTypes.RESET_LIST_TERMINALS_ERROR;
}

export type ListTerminalsAction =
  | ListTerminals
  | ListTerminalsSuccess
  | ListTerminalsError
  | ResetListTerminals
  | ResetListTerminalsError
  | SelectEntityType
  | SelectTerminal
  | SelectTerminalSingle
  | ResetSelectedTerminals;

export function listTerminals(searchString: string = '', currentPage: number = 1, filter): ListTerminals {
  return {
    type: ListTerminalsActionTypes.LIST_TERMINALS_REQUEST,
    searchString,
    currentPage,
    filter,
  };
}

export function listTerminalsSucceeded(total: number, terminals): ListTerminalsSuccess {
  return {
    type: ListTerminalsActionTypes.LIST_TERMINALS_SUCCESS,
    terminals,
    total,
  };
}

export function listTerminalsFailed(error: Error): ListTerminalsError {
  return {
    type: ListTerminalsActionTypes.LIST_TERMINALS_ERROR,
    error,
  };
}

export function selectEntityType(entityType: FeeEntity): SelectEntityType {
  return {
    type: ListTerminalsActionTypes.SELECT_ENTITY_TYPE,
    entityType,
  };
}

export function selectTerminal(terminal: Terminal): SelectTerminal {
  return {
    type: ListTerminalsActionTypes.SELECT_TERMINAL,
    terminal,
  };
}

export function selectTerminalSingle(terminal: Terminal): SelectTerminalSingle {
  return {
    type: ListTerminalsActionTypes.SELECT_TERMINAL_SINGLE,
    terminal,
  };
}

export function resetSelectedTerminals(): ResetSelectedTerminals {
  return {
    type: ListTerminalsActionTypes.RESET_SELECTED_TERMINALS,
  };
}

export function resetListTerminals(): ResetListTerminals {
  return {
    type: ListTerminalsActionTypes.RESET_LIST_TERMINALS,
  };
}

export function resetListTerminalsError(): ResetListTerminalsError {
  return {
    type: ListTerminalsActionTypes.RESET_LIST_TERMINALS_ERROR,
  };
}
