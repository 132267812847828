/* External dependencies */
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { createHttpLink } from '@apollo/client/core';
import * as AWSCognito from 'amazon-cognito-identity-js';
import { LocalStorageWrapper, persistCache } from 'apollo3-cache-persist';
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import AWS, { CognitoIdentityCredentials } from 'aws-sdk/global';
import { v4 as uuidv4 } from 'uuid';

/* Local dependencies */
import {
  InitClientFailed,
  initClientFailed,
  InitClientSucceeded,
  initClientSucceeded,
} from '../components/login/redux/actions';
import { COGNITO_USER_POOL_DATA, config, getSession } from './cognito';
import CustomCognitoUserSession from './cognitoUserSession';

type AppSyncClient = ApolloClient<NormalizedCacheObject>;
let client: AppSyncClient;

export let anonymousClient: ApolloClient<NormalizedCacheObject>;
let credentials: any;

const { APPSYNC_ENDPOINT, COGNITO_IDENTITY_POOL_ID, COGNITO_USER_POOL_ID, REGION } = config;

export function setupClient(session: CustomCognitoUserSession) {
  // In order to access environment variables the full version
  // like `process.env.XXX` must be written. It will not work
  // when `process.env` is destructed.
  const idToken = session.getIdToken().getJwtToken();
  const providerName = `cognito-idp.${REGION}.amazonaws.com/${COGNITO_USER_POOL_ID}`;

  credentials = new CognitoIdentityCredentials({
    IdentityPoolId: COGNITO_IDENTITY_POOL_ID,
    Logins: {
      [providerName]: idToken,
    },
  });

  AWS.config.update({
    region: REGION,
    credentials,
  });

  const appSyncConfig = {
    url: APPSYNC_ENDPOINT,
    region: REGION,
    auth: {
      type: AUTH_TYPE.AWS_IAM as AUTH_TYPE.AWS_IAM,
      credentials: AWS.config.credentials!,
    },
    offlineConfig: {
      keyPrefix: `client-instance-${uuidv4()}`,
    },
  };

  const httpLink = new HttpLink({
    uri: appSyncConfig.url,
    fetch,
  });

  const link = ApolloLink.from([
    createAuthLink(appSyncConfig),
    createSubscriptionHandshakeLink(appSyncConfig, httpLink),
  ]);

  return (client = new ApolloClient({
    link,
    cache: new InMemoryCache({
      addTypename: false,
    }),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
    },
  }));
}

export async function setAnonymousClient(): Promise<ApolloClient<NormalizedCacheObject>> {
  if (anonymousClient) {
    return anonymousClient;
  }

  const anonymousClientConfig = {
    url: APPSYNC_ENDPOINT,
    region: REGION,
    auth: {
      type: AUTH_TYPE.API_KEY as AUTH_TYPE.API_KEY,
      apiKey: process.env.GATSBY_AVERSPAY_APP_SYNC_API_KEY!,
    },
    offlineConfig: {
      keyPrefix: `client-instance-${uuidv4()}`,
    },
  };

  const { url } = anonymousClientConfig;
  const httpLink = createHttpLink({ uri: url });

  const link = ApolloLink.from([
    createAuthLink(anonymousClientConfig),
    createSubscriptionHandshakeLink(anonymousClientConfig, httpLink),
  ]);

  const cache = new InMemoryCache();

  if (typeof window !== 'undefined') {
    await persistCache({
      cache,
      storage: new LocalStorageWrapper(window.localStorage),
    });
  }

  return (anonymousClient = new ApolloClient({
    link,
    cache,
  }));
}

export async function cleanUpSession() {
  if (client) {
    try {
      await client.clearStore();
    } catch (_) {
      // Ignore the error since we anyway wanted to cleanup the client.
    }

    client = undefined;
  }

  if (AWS.config.credentials) {
    (AWS.config.credentials as CognitoIdentityCredentials).clearCachedId();
    AWS.config.credentials = null;
  }

  return initClientFailed();
}

export async function getClient(): Promise<ApolloClient<NormalizedCacheObject>> {
  let userPool;
  let currentuser;
  let expireTime;

  try {
    userPool = new AWSCognito.CognitoUserPool(COGNITO_USER_POOL_DATA);
    currentuser = userPool.getCurrentUser();
    expireTime = credentials.expireTime;
  } catch (e) {
    return setAnonymousClient();
  }

  if (expireTime && credentials.needsRefresh()) {
    const session = await getSession();

    return new Promise((resolve, reject) => {
      currentuser?.refreshSession(session.getRefreshToken(), (err, newSession) => {
        if (err) {
          reject(err);
        }

        const client = setupClient(newSession);

        resolve(client);
      });
    });
  }

  return client;
}

export async function initClient(): Promise<InitClientFailed | InitClientSucceeded> {
  const session: CustomCognitoUserSession = await getSession();

  return initClientSucceeded(session);
}
