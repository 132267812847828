/*External dependencies */
import gql from 'graphql-tag';

export const listLimitsQuery = gql`
  query listLimits {
    listLimits {
      total
      limits {
        id
        name
        identified {
          corporate {
            monthlyTransactionLimit
            transactionLimit
          }
          personal {
            monthlyTransactionLimit
            transactionLimit
          }
        }
        offlineIdentified {
          corporate {
            monthlyTransactionLimit
            transactionLimit
          }
          personal {
            monthlyTransactionLimit
            transactionLimit
          }
        }
      }
    }
  }
`;
