// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { Account } from '../../../accounts/listAccounts/types';
import { User } from '../../userTypes';
import { GetUserAction, GetUserActionTypes } from './actions';
import validateUserFieldsForAdmin from './validateUserFieldsForAdmin';

export interface GetUserState {
  error?: Error;
  loading: boolean;
  isFormChanged?: boolean;
  user?: User;
  errorRootUser?: Error;
  pinNumberError?: Error;
  birthDateError?: Error;
  firstNameError?: Error;
  frontError?: Error;
  backError?: Error;
  documentIdentificationStatusError?: Error;
  faceIdentificationStatusError?: Error;
  selfieError?: Error;
  lastNameError?: Error;
  phoneNumberError?: Error;
  loadingRootUser?: boolean;
  rootUser?: User;
  selectedAccounts?: Account[];
  genderError?: Error;

  // UPDATE USER
  updateLoading: boolean;
  isUpdateSuccess: boolean;
  updateError?: Error;
  mainImage?: string;
  backupIdentificationDocument?: any;
  isIdentificationDocumentHasChanged?: boolean;
}

export const initialGetUserState: GetUserState = {
  loading: false,
  loadingRootUser: false,
  updateLoading: false,
  isUpdateSuccess: false,
};

export default function getUserReducer(state = initialGetUserState, action: GetUserAction) {
  switch (action.type) {
    case GetUserActionTypes.GET_USER_REQUEST:
      return update(state, {
        isFormChanged: { $set: false },
        $unset: ['error'],
        loading: { $set: true },
      });

    case GetUserActionTypes.GET_USER_SUCCESS:
      return update(state, {
        user: { $set: action.user },
        mainImage: { $set: action.user?.identificationDocument?.personalData?.front },
        loading: { $set: false },
      });

    case GetUserActionTypes.GET_USER_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case GetUserActionTypes.SELECTED_ACCOUNT:
      return update(state, {
        selectedAccounts: { $set: [action.account] },
      });

    case GetUserActionTypes.SET_USER_IMAGE:
      return update(state, {
        mainImage: { $set: action.image },
      });

    case GetUserActionTypes.GET_ROOT_USER_REQUEST:
      return update(state, {
        $unset: ['errorRootUser'],
        loadingRootUser: { $set: true },
      });

    case GetUserActionTypes.GET_ROOT_USER_SUCCESS:
      return update(state, {
        rootUser: { $set: action.rootUser },
        loadingRootUser: { $set: false },
      });

    case GetUserActionTypes.GET_ROOT_USER_ERROR:
      return update(state, {
        errorRootUser: { $set: action.error },
        loadingRootUser: { $set: false },
      });

    //@ts-ignore
    case GetUserActionTypes.UPDATE_USER_FIELDS:
      //@ts-ignore
      return validateUserFieldsForAdmin(state, action.updates);

    case GetUserActionTypes.RESET_GET_USER_STATE:
      return update(state, { $set: initialGetUserState });

    //UPDATE USER

    case GetUserActionTypes.ADMIN_UPDATE_USER_REQUEST:
    case GetUserActionTypes.OPERATOR_UPDATE_USER_REQUEST:
    case GetUserActionTypes.COMPLIANCE_MANAGER_UPDATE_USER_REQUEST:
    case GetUserActionTypes.MANAGER_UPDATE_USER_REQUEST: {
      return {
        ...state,
        updateLoading: true,
        isUpdateSuccess: false,
      };
    }

    case GetUserActionTypes.UPDATE_USER_SUCCESS: {
      return {
        isFormChanged: false,
        updateLoading: false,
        isUpdateSuccess: true,
        user: action.user,
        mainImage: action.user?.identificationDocument?.personalData?.front,
      };
    }

    case GetUserActionTypes.UPDATE_USER_ERROR: {
      return update(state, {
        updateLoading: { $set: false },
        isUpdateSuccess: { $set: false },
        updateError: { $set: action.error },
      });
    }

    case GetUserActionTypes.UPDATE_USER_RESET: {
      return {
        updateLoading: false,
        isUpdateSuccess: false,
      };
    }

    case GetUserActionTypes.RESET_USER_ERROR: {
      return update(state, { $unset: ['updateError'] });
    }

    default:
      return state;
  }
}
