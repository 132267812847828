/*External dependencies */
import { filter, switchMap } from 'rxjs';

/*Local dependencies */
import { getClient } from '../../../../clients/averspay';
import { Limits } from '../../../../components/limits/limitTypes';
import {
  CreateLimitActions,
  CreateLimitActionTypes,
  createLimitFailed,
  CreateLimitRequest,
  createLimitSucceeded,
} from './actions';
import { createLimitMutation } from './mutation';

export default function createLimitEpic(action$) {
  return action$.pipe(
    filter((action: CreateLimitActions) => action.type === CreateLimitActionTypes.CREATE_LIMIT_REQUEST),
    switchMap((action: CreateLimitRequest) =>
      createLimit(action)
        .then(createLimitSucceeded)
        .catch((error: Error) => createLimitFailed(error)),
    ),
  );
}

export async function createLimit(action): Promise<Limits> {
  const graphQLClient = await getClient();

  const { limit } = action;

  const {
    data: { updateLimit },
  } = await graphQLClient.mutate({
    mutation: createLimitMutation,
    variables: {
      input: limit,
    },
  });

  return updateLimit;
}
