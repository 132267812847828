/* External dependencies */
import update from 'immutability-helper';

/*Local dependencies */
import { UpsertLimitInput } from '../../../../components/limits/limitTypes';
import { UpdateLimitActions, UpdateLimitActionTypes } from '../../../../components/limits/updateLimit/redux/actions';
import { CreateLimitActions, CreateLimitActionTypes } from './actions';
import { validateLimit } from './validation';

export interface LimitState {
  limit: UpsertLimitInput;
  errors: UpsertLimitInput;
  isSuccess: boolean;
  loading: boolean;
  error: Error;
  validationPassed: boolean;
}

export const limitInitialState: LimitState = {
  limit: {
    name: '',
    identified: {
      corporate: {
        monthlyTransactionLimit: null,
        transactionLimit: null,
      },
      personal: {
        monthlyTransactionLimit: null,
        transactionLimit: null,
      },
    },
    offlineIdentified: {
      corporate: {
        monthlyTransactionLimit: null,
        transactionLimit: null,
      },
      personal: {
        monthlyTransactionLimit: null,
        transactionLimit: null,
      },
    },
  },
  errors: {
    name: '',
    identified: {
      corporate: {
        monthlyTransactionLimit: null,
        transactionLimit: null,
      },
      personal: {
        monthlyTransactionLimit: null,
        transactionLimit: null,
      },
    },
    offlineIdentified: {
      corporate: {
        monthlyTransactionLimit: null,
        transactionLimit: null,
      },
      personal: {
        monthlyTransactionLimit: null,
        transactionLimit: null,
      },
    },
  },
  loading: false,
  isSuccess: false,
  error: null,
  validationPassed: false,
};

export type LimitActions = CreateLimitActions | UpdateLimitActions;

export default function limitReducer(state: LimitState = limitInitialState, action: LimitActions) {
  switch (action.type) {
    case CreateLimitActionTypes.CHANGE_LIMIT: {
      return update(state, {
        limit: { $set: action.limit },
      });
    }

    case CreateLimitActionTypes.CREATE_LIMIT_REQUEST:
    case UpdateLimitActionTypes.UPDATE_LIMIT_REQUEST: {
      return update(state, {
        loading: { $set: true },
      });
    }

    case CreateLimitActionTypes.CREATE_LIMIT_SUCCESS: {
      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: true },
      });
    }

    case CreateLimitActionTypes.CREATE_LIMIT_FAILURE: {
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });
    }

    case CreateLimitActionTypes.RESET_CREATE_LIMIT: {
      return update(state, {
        $set: {
          ...limitInitialState,
          limit: {
            ...limitInitialState.limit,
            identified: {
              ...limitInitialState.limit.identified,
              corporate: {
                ...limitInitialState.limit.identified.corporate,
              },
              personal: {
                ...limitInitialState.limit.identified.personal,
              },
            },
            offlineIdentified: {
              ...limitInitialState.limit.identified,
              corporate: {
                ...limitInitialState.limit.identified.corporate,
              },
              personal: {
                ...limitInitialState.limit.identified.personal,
              },
            },
          },
          errors: {
            ...limitInitialState.limit,
            identified: {
              ...limitInitialState.limit.identified,
              corporate: {
                ...limitInitialState.limit.identified.corporate,
              },
              personal: {
                ...limitInitialState.limit.identified.personal,
              },
            },
            offlineIdentified: {
              ...limitInitialState.limit.identified,
              corporate: {
                ...limitInitialState.limit.identified.corporate,
              },
              personal: {
                ...limitInitialState.limit.identified.personal,
              },
            },
          },
        },
      });
    }

    case CreateLimitActionTypes.RESET_CREATE_LIMIT_ERROR: {
      return update(state, { $unset: ['error'] });
    }

    case CreateLimitActionTypes.SUBMIT_LIMIT: {
      const errors = { ...state.errors };
      const validationPassed = validateLimit(state.limit, errors);

      return update(state, {
        errors: { $set: errors },
        validationPassed: { $set: validationPassed },
      });
    }

    default:
      return state;
  }
}
